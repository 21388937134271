import axios from 'axios';
import {getUrlParam} from 'helper';

export const put_nav = (data) => ({
    type: "PUT_NAV",
    data
})

export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data
})

export const check_login = () => {
    return dispatch => {
        let {pathname} = window.location;
        dispatch(toggle_loader(true))
        axios.get('otrs/check_login/')
        .then(resp => {
            dispatch({
                type: 'PUT_USER',
                data: resp.data
            })

            if (pathname === '/') {
                window.location.href = process.env.REACT_APP_BASE_URL + 'dashboard';
            }
        })
        .catch(err => {
            if (pathname !== '/') {
                window.location.href = process.env.REACT_APP_BASE_URL;
            }
        })
        .then(() => {
            dispatch(toggle_loader(false))
        })
    }
}

export const set_token = () =>{
    return dispatch => {
        let token = getUrlParam('token');
        if(token) {
            window.localStorage.token = token;
            window.location.href = `${process.env.REACT_APP_BASE_URL}`;
        }else{
            dispatch(check_login())
        }
    }
}

export const get_categories = () => {
    return dispatch => {
        axios.get('otrs/categories/')
        .then(resp => {
            dispatch({
                type: "PUT_CATEGORIES",
                data: resp.data
            })
        })
        .catch(err => {
            console.log(err.response)
        })
    }
}

export const map_err = (err) => {
    return dispatch => {
        let msg = "Terjadi Kesalahan, Silahkan Refresh Browser Anda";
        if (err.response.status !== 500) {
            msg = err.response.data.msg
        }
        alert(msg);
    }
}