import React, { Component } from 'react';
import { date_parser } from 'helper';
import DOMPurify from 'dompurify';

class Timeline extends Component {

    sent_mapper = (is_sent) => {
        var classname, text;
        switch (is_sent) {
            case 1:
                classname = 'bd-success tx-success';
                text = 'SENT';
                break;
            default:
                classname = 'bd-warning tx-warning';
                text = 'PENDING';
                break;
        }
        return {
            "class": classname,
            "text": text
        }
    }

    format_content = (data) => {
        // Replace CDN Link
        return data.replaceAll('https://cdn.help.garena.co.id/', 'https://proxy.cdn.garena.co.id/');
    }

    loop_timeline = () => {
        const { conversation } = this.props;

        if (!conversation) {
            return
        }

        return conversation.map((dt, idx) =>
            <div key={idx} className={(idx === 0) ? "timeline-item timeline-day" : "timeline-item"}>
                <div className="timeline-time">
                    <div>{date_parser(dt.created_at)}</div>
                </div>
                <div className="timeline-body pd-r-25">
                    <p className="timeline-title d-flex align-items-center">
                        {dt.agent_email ? (
                            <>
                                <span className="badge badge-pill badge-info">Agent</span>
                                <span className="ml-1">{dt.agent_email.name}</span>
                            </>
                        ) : (

                            <>
                                <span className="badge badge-pill badge-warning">User</span>
                                <span className="ml-1">{dt.user_open?.nickname}</span>
                            </>
                        )}
                    </p>
                    <p className="timeline-text text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.format_content(dt.content)) }}></p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="Timeline" className="timeline-group tx-center">
                {this.loop_timeline()}
            </div>
        );
    }
}

export default Timeline;