import React, { Component } from "react";
import Title from "partial/Title";

class Dashboard extends Component {
	componentDidMount() {
		setTimeout(() => {
			window.iFrameResize({ log: false, autoResize: false }, "#myIframe");
		}, 2000);
	}

	render() {
		// let url = "http://103.247.218.124:3044/public/dashboard/56b452ee-3f70-4440-a838-91c1750bd30a";
		// if (process.env.NODE_ENV === "production") {
		// 	url = process.env.REACT_APP_BASE_URL + "public/dashboard/513cd0f4-ca58-4d42-936a-fa3d5befd9c9/";
		// }

		return (
			<div id="dashboard">
				<Title>Dashboard</Title>
				{/* <iframe id="myIframe" title="dashboard" src={url} frameBorder="0" width="100%" height="600" ></iframe> */}
			</div>
		);
	}
}

export default Dashboard;
